<div id="div-values-{{filter.id}}" class="inline-label">
  <op-date-picker (onChange)="begin = isoDateParser($event)">
    <input [ngModel]="isoDateFormatter(begin)"
           [focus]="shouldFocus"
           (ngModelChange)="begin = isoDateParser($event)"
           required
           id="values-{{filter.id}}-begin"
           name="v[{{filter.id}}]-begin"
           class="advanced-filters--date-field"
           size="10"
           type="text"/>
  </op-date-picker>

  <span class="advanced-filters--affix" [textContent]="text.spacer">
  </span>

  <op-date-picker (onChange)="end = isoDateParser($event)">
    <input [ngModel]="isoDateFormatter(end)"
           (ngModelChange)="end = isoDateParser($event)"
           id="values-{{filter.id}}-end"
           name="v[{{filter.id}}]-end"
           class="advanced-filters--date-field"
           size="10"
           type="text"/>
  </op-date-picker>
  <span class="advanced-filters--tooltip-trigger -multiline"
        *ngIf="isTimeZoneDifferent"
        [attr.data-tooltip]="timeZoneText">
    <op-icon icon-classes="icon icon-warning"></op-icon>
  </span>
</div>
