<div id="div-values-{{filter.id}}"
  class="inline-label">
  <op-date-picker (onChange)="begin = parser($event)">
    <input [ngModel]="formatter(begin)"
           [focus]="shouldFocus"
           (ngModelChange)="begin = parser($event)"
           required
           id="values-{{filter.id}}-begin"
           name="v[{{filter.id}}]-begin"
           class="advanced-filters--date-field"
           size="10"
           type="text"/>
  </op-date-picker>

  <span class="advanced-filters--affix" [textContent]="text.spacer">
  </span>

  <op-date-picker (onChange)="end = parser($event)">
    <input [ngModel]="formatter(end)"
           (ngModelChange)="end = parser($event)"
           required
           id="values-{{filter.id}}-end"
           name="v[{{filter.id}}]-end"
           class="advanced-filters--date-field"
           size="10"
           type="text"/>
  </op-date-picker>
</div>
