
    <div class="op-modal--portal">
      <div class="op-modal--modal-container"
           data-indicator-name="modal"
           tabindex="0">
        <div class="op-modal--modal-header">
          <a class="op-modal--modal-close-button">
            <i
              class="icon-close"
              (click)="closeMe($event)"
              [attr.title]="text.closePopup">
            </i>
          </a>
        </div>

        <h3 [textContent]="text.title"></h3>

        <iframe [src]="teaserVideoUrl()"
                width="800"
                height="500"
                class="boards--teaser-video"
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen>
        </iframe>
      </div>
    </div>

  