
    <ng-select [items]="options"
               [virtualScroll]="true"
               bindLabel="name"
               bindValue="value"
               [(ngModel)]="selectedOption"
               (change)="onModelChange($event)"
               [clearable]="false"
               appendTo="body">
      <ng-template ng-label-tmp let-item="item">
        <span [ngClass]="highlightColor(item)">{{item.name}}</span>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index">
        <span [ngClass]="highlightColor(item)">{{item.name}}</span>
      </ng-template>
    </ng-select>
  