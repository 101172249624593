<img *ngIf="userAvatarUrl && !useFallback"
     [class]="'avatar--fallback ' + classes"
     [attr.src]="userAvatarUrl"
     [attr.title]="userName"
     [alt]="userName"
     (error)="replaceWithDefault()" />

<div *ngIf="(!userAvatarUrl || useFallback) && userInitials"
     [class]="'avatar-default ' + classes"
     [textContent]="userInitials"
     [style.background]="colorCode">
</div>
