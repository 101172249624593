<div class="-columns-2">
  <div class="attributes-key-value"
       [ngClass]="{'-span-all-columns': descriptor.spanAll }"
       *ngFor="let descriptor of group.members; trackBy:trackByName">
    <ng-template [ngIf]="!shouldHideField(descriptor)">
      <div
        class="attributes-key-value--key"
        *ngIf="!descriptor.multiple && descriptor.field">
        <wp-replacement-label [fieldName]="descriptor.name">
          {{ descriptor.label }}
          <span class="required"
                *ngIf="descriptor.field!.required && descriptor.field!.writable"> *</span>
        </wp-replacement-label>
        <attribute-help-text [attribute]="descriptor.name" [attributeScope]="'WorkPackage'"></attribute-help-text>
      </div>
      <div *ngIf="!descriptor.multiple && descriptor.field"
           class="attributes-key-value--value-container">

        <editable-attribute-field *ngIf="descriptor.field!.isFormattable"
                       class="wp-edit-formattable-field"
                       [resource]="workPackage"
                       [isDropTarget]="true"
                       [fieldName]="descriptor.name">
        </editable-attribute-field>
        <editable-attribute-field *ngIf="!descriptor.field.isFormattable"
                       [resource]="workPackage"
                       [fieldName]="descriptor.name">
        </editable-attribute-field>
      </div>
      <div
        class="attributes-key-value--key"
        *ngIf="descriptor.multiple">
        <wp-replacement-label [fieldName]="descriptor.name">
          {{ descriptor.label }}
        </wp-replacement-label>
        <attribute-help-text [attribute]="descriptor.name" [attributeScope]="'WorkPackage'"></attribute-help-text>
      </div>
      <div
        *ngIf="descriptor.multiple"
        class="attributes-key-value--value-container -minimal">
        <editable-attribute-field [fieldName]="descriptor.fields[0].name"
                       [resource]="workPackage"
                       [wrapperClasses]="'-small -shrink'"
                       [displayPlaceholder]="text[descriptor.name][descriptor.fields[0].name]">
        </editable-attribute-field>

        <span class="attributes-key-value--value-separator"></span>

        <editable-attribute-field [fieldName]="descriptor.fields[1].name"
                       [resource]="workPackage"
                       [wrapperClasses]="'-small -shrink'"
                       [displayPlaceholder]="text[descriptor.name][descriptor.fields[1].name]">
        </editable-attribute-field>
      </div>
    </ng-template>
  </div>
</div>
