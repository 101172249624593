
    <input type="number"
           step="any"
           class="inline-edit--field"
           [attr.aria-required]="required"
           [attr.required]="required"
           [disabled]="inFlight"
           [(ngModel)]="value"
           (keydown)="handler.handleUserKeydown($event)"
           (focusout)="handler.onFocusOut()"
           [attr.lang]="locale"
           [id]="handler.htmlId" />
  