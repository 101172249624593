
    <ng-select [items]="options"
               [labelForId]="labelForId"
               bindLabel="label"
               [multiple]="multiselect"
               [virtualScroll]="true"
               [ngModel]="selected"
               appendTo="body"
               placeholder="Please select"
               (ngModelChange)="updateSelection($event)">
      <ng-template ng-option-tmp let-item="item" let-index="index">
        {{ item.label }}
      </ng-template>
    </ng-select>
  