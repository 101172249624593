<widget-header
  [name]="widgetName"
  [editable]="isEditable">

  <widget-menu
      [resource]="resource">
  </widget-menu>
</widget-header>

<div class="grid--widget-content -allow-inner-overflow">
  <edit-form *ngIf="(project$ | async) as project"
             [resource]="project">
    <editable-attribute-field [resource]="project"
                              [fieldName]="'description'">
    </editable-attribute-field>
  </edit-form>
</div>
