
    <op-date-picker
      tabindex="-1"
      (onChange)="onValueSelected($event)"
      [initialDate]="defaultDate">

      <input [ngModel]="formatter(value)"
             (ngModelChange)="value = parser($event);"
             type="text"
             class="inline-edit--field"
             (keydown)="handler.handleUserKeydown($event)"
             [attr.required]="required"
             [disabled]="inFlight"
             [attr.placeholder]="placeholder"
             [id]="handler.htmlId" />

    </op-date-picker>

  