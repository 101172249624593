
      <div class="notification-box -ee-upsale"
           [ngClass]="{'-left-margin': leftMargin }">
        <div class="notification-box--content">
          <p class="-bold" [textContent]="text.enterpriseFeature"></p>
          <p [textContent]="textMessage"></p>
          <a [href]="eeLink()"
             target='blank'
             [textContent]="linkMessage"></a>
        </div>
      </div>
  