<h3 class="widget-box--header"
    [ngClass]="{ '-editable': isRenameable }">

  <editable-toolbar-title [title]="name"
                          (onSave)="renamed($event)"
                          [editable]="isRenameable"
                          class="widget-box--header-title">
  </editable-toolbar-title>

  <ng-content></ng-content>
</h3>
