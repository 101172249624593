
  <a (accessibleClick)="handleClick($event)"
     role="link"
     [ngClass]="linkClass"
     [attr.disabled]="isDisabled || undefined"
     [attr.title]="linkTitle"
     [attr.aria-label]="linkAriaLabel"
     href>
    <span [ngClass]="spanClass">
      <ng-content></ng-content>
    </span>
  </a>
  