
      <button class="button"
              id="wp-view-toggle-button"
              wpViewDropdown>
        <op-icon icon-classes="button--icon icon-view-{{view}}"></op-icon>
        <span class="button--text"
              aria-hidden="true"
              [textContent]="text[view]">
        </span>
        <op-icon icon-classes="button--icon icon-small icon-pulldown"></op-icon>
      </button>
