
    <resizer class="main-menu--resizer"
             [customHandler]="true"
             [cursorClass]="'col-resize'"
             (end)="resizeEnd()"
             (start)="resizeStart()"
             (move)="resizeMove($event)">
      <a href="#"
         [attr.title]="toggleTitle"
         class="main-menu--navigation-toggler"
         (accessibleClick)="toggleService.toggleNavigation($event)">
        <i class="icon-resizer-vertical-lines"
           aria-hidden="true"></i>
      </a>
    </resizer>
  