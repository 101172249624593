
    <input type="number"
           step="0.01"
           class="inline-edit--field"
           [attr.aria-required]="required"
           [ngModel]="formatter(value)"
           (ngModelChange)="value = parser($event)"
           [attr.required]="required"
           (keydown)="handler.handleUserKeydown($event)"
           [disabled]="inFlight"
           [id]="handler.htmlId" />
  